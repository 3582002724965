import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import { ThemeProvider } from '@material-ui/styles';

import MuiTheme from './theme';

// Layout Blueprints

import {
  LeftSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// load components with LeftSidebar start
// const Overview = lazy(() => import('./example-pages/Overview'));

// const DashboardMonitoring = lazy(() =>
//   import('./example-pages/DashboardMonitoring')
// );
const UnitSelection = lazy(() =>
  import('./components/UnitSelection/Unitselection')
);
const Header = lazy(() => import('./components/UnitSelection/header'));
const Costsheet = lazy(() => import('./components/Costsheet/Costsheet'));
const PaymentScheme = lazy(() =>
  import('./components/PaymentScheme/PaymentScheme')
);
const Kyc = lazy(() => import('./components/Kyc/Kyc'));
const UnitDetails = lazy(() =>
  import('./components/UnitSelection/Unitdetails')
);
const Payment = lazy(() => import('./components/Payment'));
//---------- load components with LeftSidebar end ----------

//---------- load components with CollapsedSidebar start ----------
// const PageCalendar = lazy(() => import('./example-pages/PageCalendar'));
//---------- load components with CollapsedSidebar end ----------

//---------- load components with MinimalLayout(Full page with out header and footer) start ----------
const UserLogin = lazy(() => import('./components/UserLogin'));
//---------- load components with MinimalLayout(Full page with out header and footer) end ----------
const Login = lazy(() => import('./components/Login/Login'));
const Register = lazy(() => import('./components/Register'));
const Admin = lazy(() => import('./components/Admin/admin'));
const KycList = lazy(() => import('./components/Admin/KycList'));
const UserList = lazy(() => import('./components/Admin/UserList'));
const OtpVerification = lazy(() =>
  import('./components/Login/OtpVerification')
);

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/unitselection" />
            <Route
              path={[
                '/Login',
                '/OtpVerification',
                '/Register',
                '/Admin',
                '/kyc-list',
                '/user-list'
              ]}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/Login" component={Login} />
                    <Route path="/Register" component={Register} />
                    <Route path="/Admin" component={Admin} />
                    <Route path="/kyc-list" component={KycList} />
                    <Route
                      path="/OtpVerification"
                      component={OtpVerification}
                    />
                    <Route path="/user-list" component={UserList} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route>
            <Route
              path={[
                '/unitselection',
                '/filters',
                '/Unitdetails',
                '/Costsheet',
                '/PaymentScheme',
                '/Kyc',
                '/header',
                '/payment'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/Unitdetails" component={UnitDetails} />
                    <Route path="/unitselection" component={UnitSelection} />
                    <Route path="/Costsheet" component={Costsheet} />
                    <Route path="/PaymentScheme" component={PaymentScheme} />
                    <Route path="/Kyc" component={Kyc} />
                    <Route path="/header" component={Header} />
                    <Route path="/payment" component={Payment} />
                    {/* <Route path="/filters" component={FiltersComponent} /> */}
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route path={['/PageLoginBasic', '/user-login']}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/user-login" component={UserLogin} />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
