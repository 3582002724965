import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { gotToStep, selectCurrentStep } from '../../app/relataSlice';
// import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

// import SidebarUserbox from '../SidebarUserbox';
// import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { useHistory } from 'react-router';
const SidebarMenu = (props) => {
  const { sidebarUserbox = false } = props;

  // const toggleSidebarMobile = () => {
  //   //setSidebarToggleMobile(false)
  // };
  // const [selectUnitOpen, setSelectUnitOpen] = useState();
  // setSelectUnitOpen(true);
  // const selectUnitOpen = true;
  const currentStep = useSelector(selectCurrentStep);
  console.log(currentStep);
  const dispatch = useDispatch();
  const history = useHistory();

  function ChangeStep(step) {
    if (step < currentStep) {
      dispatch(gotToStep(step));

      switch (step) {
        case 1:
          history.push('/unitselection');
          break;
        case 2:
          history.push('/Costsheet');
          break;
        case 3:
          history.push('/PaymentScheme');
          break;
        case 4:
          history.push('/kyc');
          break;
        case 5:
          history.push('/paynow');
          break;
        default:
          history.push('/unitselection');
          break;
      }
    }
  }
  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span></span>
          </div>
          <ul>
            <li>
              <a
                className={`${clsx({
                  active: currentStep === 1
                })} nav-link-simple`}
                onClick={() => ChangeStep(1)}>
                <span className="sidebar-item-label text-color">
                  Plot Selection
                </span>
                <span className="sidebar-icon text-color">
                  <ViewCarouselIcon />
                </span>
              </a>
            </li>
            <li>
              <a
                className={`${clsx({
                  active: currentStep === 2
                })} nav-link-simple`}
                onClick={() => ChangeStep(2)}>
                <span className="sidebar-item-label text-color">
                  Price Sheet
                </span>
                <span className="sidebar-icon text-color">
                  <DescriptionIcon />
                </span>
              </a>
            </li>
            <li>
              <a
                className={`${clsx({
                  active: currentStep === 3
                })} nav-link-simple`}
                onClick={() => ChangeStep(3)}>
                <span className="sidebar-item-label text-color">
                  Payment Plan
                </span>
                <span className="sidebar-icon text-color">
                  <AssignmentTurnedInIcon />
                </span>
              </a>
            </li>
            <li>
              <a
                className={`${clsx({
                  active: currentStep === 4
                })} nav-link-simple`}
                onClick={() => ChangeStep(4)}>
                <span className="sidebar-item-label text-color">KYC</span>
                <span className="sidebar-icon text-color">
                  <ContactMailIcon />
                </span>
              </a>
            </li>
            <li>
              <a
                className={`${clsx({
                  active: currentStep === 5
                })} nav-link-simple`}
                onClick={() => ChangeStep(5)}>
                <span className="sidebar-item-label text-color">Payment</span>
                <span className="sidebar-icon text-color">
                  <AccountBalanceWalletIcon />
                </span>
              </a>
            </li>
            {/* <li>
              <NavLink
                className={`${clsx({
                  active: currentStep === 6
                })} nav-link-simple`}
                onClick={() => dispatch(gotToStep(6))}
                to="/Overview">
                <span className="sidebar-item-label text-color">Details</span>
                <span className="sidebar-icon text-color">
                  <BallotTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={`${clsx({
                  active: currentStep === 7
                })} nav-link-simple`}
                onClick={() => dispatch(gotToStep(7))}
                to="/Overview">
                <span className="sidebar-item-label text-color">Agreement</span>
                <span className="sidebar-icon text-color">
                  <BallotTwoToneIcon />
                </span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   // sidebarUserbox: state.ThemeOptions.sidebarUserbox,
//   // sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
// });

// const mapDispatchToProps = (dispatch) => ({
//   setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
// });

export default SidebarMenu;
