import React from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';

import 'date-fns';
import '../../assets/header-sidebar.css';
import brand_logo from '../../assets/images/brand_logo.png';
import brand_logo_2 from '../../assets/images/brand_logo_2.png';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const Header = (props) => {
  const history = useHistory();
  let userdata = JSON.parse(localStorage.getItem('userData'));
  let userId = '';
  if (userdata != null) {
    if (userdata.id != null) {
      userId = userdata.id;
    } else {
      history.push('/login');
    }
  } else {
    history.push('/login');
  }
  const {
    headerShadow = true,
    headerBgTransparent = true,
    sidebarToggleMobile = true,
    setSidebarToggleMobile = true
  } = props;
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  // const gsDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // let today = new Date();
  // let dayName = gsDayNames[today.getDay()];
  // let date =
  //   dayName +
  //   ', ' +
  //   today.getDate() +
  //   ' ' +
  //   today.toLocaleString('default', { month: 'short' });

  // let time = today.getHours() + ':' + today.getMinutes();
  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const childCompRef = React.useRef(null);
  React.useLayoutEffect(() => {
    console.log(childCompRef);
  }, []);

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <img src={brand_logo} alt="Isle Of Bliss" />
          {/* <a className="barnd-name">
            Isle Of <b>Bliss</b>
          </a> */}
          {/* <HeaderMenu /> */}
        </div>

        <div className="app-header--pane">
          {/* <a className="header-brochure">Brochure</a>
          <div className="vertical-line"></div>
          <a className="header-date-time">
            {time}.{date}
          </a> */}
          <Button
            variant="contained"
            className="m-1 btn-first login-btn on-hover"
            href={`https://relata-temp.s3.ap-south-1.amazonaws.com/dapoli/documents/brochure.pdf`}
            target="_blank">
            Brochure
          </Button>
          {userId ? (
            <>
              <Button
                variant="contained"
                className="m-1 btn-first login-btn on-hover"
                onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <>
              <NavLink className="link-register" to="Login">
                Register
              </NavLink>
              <NavLink
                variant="contained"
                className="m-1 btn-first login-btn"
                id="login-btn"
                to="Login">
                LOGIN
              </NavLink>
            </>
          )}
          <img
            src={brand_logo_2}
            className="brand-logo-2"
            alt="Isle Of Bliss"
            style={{ paddingLeft: '1rem' }}
          />
        </div>
      </div>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   // headerShadow: state.ThemeOptions.headerShadow,
//   // headerBgTransparent: state.ThemeOptions.headerBgTransparent,
//   // sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
// });

// const mapDispatchToProps = (dispatch) => ({
//   // setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
// });

export default Header;
