import { createSlice } from '@reduxjs/toolkit';

export const unitSelectionSlice = createSlice({
  name: 'unitSelection',
  initialState: {
    unitSelectionNavState: 'MASTER',
    sector: 'Sector',
    segment: 'Category',
    selectedUnit: ''
  },
  reducers: {
    setUnitSelectionNavState: (state, action) => {
      state.unitSelectionNavState = action.payload;
    },
    setSector: (state, action) => {
      state.sector = action.payload;
    },
    setSegment: (state, action) => {
      state.segment = action.payload;
    },
    setUnit: (state, action) => {
      state.selectedUnit = action.payload;
    }
  }
});

export const {
  setUnitSelectionNavState,
  setSector,
  setSegment,
  setUnit
} = unitSelectionSlice.actions;

export const getSector = (state) => state.unitSelection.sector;
export const getSegment = (state) => state.unitSelection.segment;
export const getSelectedUnit = (state) => state.unitSelection.selectedUnit;
export const getUnitSelectionNavState = (state) =>
  state.unitSelection.unitSelectionNavState;

export default unitSelectionSlice.reducer;
