import { configureStore } from '@reduxjs/toolkit';
import relataReducer from 'app/relataSlice';
import unitSelectionReducer from '../components/UnitSelection/unitSelectionSlice';
import { apiSlice } from '../components/UnitSelection/unitSelectionApiSlice';
// redux-toolkit, react-redux
export default configureStore({
  reducer: {
    relata: relataReducer,
    unitSelection: unitSelectionReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware);
  }
});
