import { createSlice } from '@reduxjs/toolkit';

export const relataSlice = createSlice({
  name: 'relata',
  initialState: {
    currentStep: 1,
    propertyId: '',
    selectedUnitId: '',
    selectedUnit: {}
  },
  reducers: {
    gotToStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    selectUnitId: (state, action) => {
      state.selectedUnitId = action.payload;
    },
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload;
    }
  }
});

// Change/Update
// Action creators are generated for each case reducer function
export const {
  gotToStep,
  setPropertyId,
  selectUnitId,
  setSelectedUnit
} = relataSlice.actions;

// Read
export const selectCurrentStep = (state) => state.relata.currentStep;

export default relataSlice.reducer;
