/* eslint-disable */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'https://api.theisleofbliss.cognilements.com/',
    baseUrl: 'https://api.theisleofbliss.cognilements.com/',
    prepareHeaders(headers) {
      //   headers.set('x-api-key', 'KEY');
      return headers;
    }
  }),
  endpoints: (build) => ({
    login: build.query({
      query: ({ email, password }) => {
        return `login/${email}/${password}`;
      },
      keepUnusedDataFor: 120
    }),
    getUnitDetails: build.query({
      query: ({ id, unit_group, block, number }) => {
        const idQuery = id ? 'id=${id}' : '';
        return `unit_details?${idQuery}&block=${block}&unit_group=${unit_group}&number=${number}`;
      },
      keepUnusedDataFor: 120
    }),
    getUnitList: build.query({
      query: ({ unit_group, block, status, number }) => {
        const idQuery = id ? 'id=${id}' : '';
        return `unit_list?${idQuery}&block=${block}&unit_group=${unit_group}&number=${number}&status=${status}`;
      },
      keepUnusedDataFor: 120
    }),
    register: build.mutation({
      query: ({ ...body }) => {
        return {
          url: `register`,
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {
  useLoginQuery,
  useRegisterMutation,
  useGetUnitDetailsQuery,
  useGetUnitListQuery
} = apiSlice;
